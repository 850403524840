<template>
  <transition name="fade" appear>
    <div>
      <el-row class="home">
        <el-col :span="4" class="left"
          ><template v-for="(item, index) in examList">
            <div
              :class="[index == typeIndex ? 'type_item_true' : 'type_item_false']"
              :key="index"
              @click="change(index)"
            >
              {{ item.examName }}
            </div>
          </template></el-col
        >
        <el-col :span="20" class="right">
          <section class="teach_list">
            <div style="width: 100%; text-align: left">
              <template v-for="(item, index) in examSubclassList">
                <div class="list_item" :key="index" @click="goDetail(item)">
                  <div class="list_text">{{ item.examSubclassName }}</div>
                </div>
              </template>
            </div>
          </section>
          <!-- <div class="point">
            <el-pagination background="" layout="prev, pager, next" :total="1000"> </el-pagination>
          </div> -->

<!-- 分页修改 -->

      <div class="point">
      <el-pagination
       
        background=""
        layout="prev, pager, next"
        :total="total"
       :page-size="pageSize"
        @current-change="handleCurrentChange"
        :current-page.sync="currPage"
        @prev-click="clickL"
        @next-click="clickR"
      >
      </el-pagination>   
       </div>

        </el-col>
      </el-row>
    </div>
  </transition>
</template>

<script>
import { CHANGE_NAVINDEX } from "../../store/mutation-types";
export default {
  data() {
    return {
      //分页条数据
      total: 0,
      pageSize: 10,
      currPage: 1,
      show: false,

      typeIndex: "0",
      //分类
      examList: [],
      //课程
      examSubclassList: [],
      examCode:"",
    };
  },
  methods: {

    // 修改当前页
    handleCurrentChange(newCurr) {
      this.currPage = newCurr;
      this.getExamSubclassList();
    },
    clickL(newCurr){
      this.currPage-= newCurr;
      console.log("左点击",newCurr)
      this.getExamSubclassList();
    },
    clickR(newCurr){
      this.currPage += newCurr;
      console.log("右点击",newCurr)
      this.getExamSubclassList();

    },

    //获得首页配置文件
    //获得考试分类列表
    getExamList(){
      var that = this;
      this.$api.exam.getExamList({}).then((res) => {
        if (res.data.code = 200){
          this.examList = res.data.data;
          this.getExamSubclassList();
        }
      });
    },
    //获取考试子类列表
    getExamSubclassList(typeIndex){
      var getData = {};
      var condition = {};
      condition.examCode =  this.examList[this.typeIndex].examCode;
      getData.condition = condition;
      getData.currPage = this.currPage;
      getData.pageSize =this.pageSize;
      console.log("getData",getData)

      this.$api.exam.getExamSubclassList(getData).then((res) => {
        if (res.data.code = 200){
          this.examSubclassList = res.data.data.data;
          this.total = res.data.data.count;
        
          console.log("this.total",this.total)

        }
      });
    },
    //切换分类
    change(index) {
      this.typeIndex = index;
      this.getExamSubclassList(this.typeIndex);
    },
    //查看详情
    goDetail(item) {
      this.$router.push({
        path: "/cecDetail",
        query:{
          type:'4',
          examSubclassCode:item.examSubclassCode,
        },
      });
    },
  },
  created() {
    this.$store.commit(CHANGE_NAVINDEX, "0");
    // this.examCode=this.$route.query.featuresCode;
    // console.log("---->examCode",this.examCode)
    this.getExamList();
  },
};
</script>

<style lang="less" scoped>
.home {
  width: 1140px;
  height: 650px;
  .left {
    background-color: #eae3d8;
    height: 100%;
    overflow: auto;
    .type_item_true {
      font-size: 20px;
      color: white;
      padding: 25px 0;
      background-color: #fab300;
    }
    .type_item_true:hover{
     cursor: pointer;
   }
    .type_item_false {
      font-size: 20px;
      color: black;
      padding: 25px 0;
    }
    .type_item_false:hover{
     cursor: pointer;
   }
  }
  .right {
    background-color: #f2f1f1;
    height: 100%;
    .teach_list {
      height: 90%;
      padding: 0 50px;
      width: 100%;
      display: flex;
      flex-direction: column;
      // padding: 0 51px;
      justify-content: flex-start;
      background-color: #f2f1f1;
      flex-flow: wrap;
    }
    .list_item {
      width: 100%;
    }
     .list_item:hover{
     cursor: pointer;
   }
    .list_text {
      font-size: 20px;
      padding: 20px 0 10px;

      border-bottom: 1px dotted black;
    }
  }
  .point {
    text-align: right;
    padding-right: 60px;
  }
}
</style>
